<template>
  <div notification-item class="no-drag" >
    <div class="header">
      <div class="title">
        <component :is="icon" class="icon" />
        <img src="/shared/img/wcg-header/wcg.svg" alt="wcg" class="wcg-icon">
        <svg class="lvup-icon" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.1959 0L0 3.10574V16.7958L4.96454 19.5656L28 16.4819V2.69962L23.1959 0Z" fill="#E81A1A" />
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.9338 3.59763L18.6496 3.62887V6.66604L18.9307 6.62184C19.3454 6.55936 19.7197 6.12962 19.7197 4.93106C19.7197 3.89327 19.4508 3.58773 19.0636 3.58773C19.0208 3.58773 18.9788 3.59154 18.9368 3.59763H18.9338ZM16.7088 1.96016C16.7088 1.96016 18.0217 1.80168 18.8779 1.67214C20.7759 1.41994 21.755 2.15904 21.755 4.68874C21.755 6.97461 20.8194 8.50767 18.8657 8.80331L18.651 8.83607V13.1998L16.7103 13.4596L16.7088 1.96016ZM10.92 11.2186V2.75634C11.6945 2.639 12.0832 2.61538 12.8608 2.49956V11.1044C12.8608 11.8503 13.0609 12.0781 13.3954 12.0278C13.7429 11.9753 13.9438 11.6872 13.9438 10.9405V2.33879L15.8578 2.08277V10.4742C15.8578 12.6618 15.0551 13.7506 13.3809 14.0066C13.2075 14.0341 13.0311 14.0486 12.8547 14.0493C11.5524 14.0455 10.9193 13.1601 10.9193 11.2186H10.92ZM4.8705 3.554L6.94262 3.27817C6.94262 3.27817 7.40394 8.26061 7.63002 10.705L7.65599 10.7012C7.86679 8.19051 8.28686 3.0953 8.28686 3.0953L10.3491 2.81947L8.81005 14.5216L6.53935 14.818L4.8705 3.554ZM1.28058 4.03244L3.2221 3.77414V13.4106L5.32859 13.1317L5.61118 14.9429L1.28058 15.522V4.03244Z"
                fill="white" />
        </svg>
        <slot name="title"></slot>
      </div>
      <div class="option">
        <span>{{ date }}</span>
        <button class="remove" @click="deleteItem">
          <svg-trash />
        </button>
      </div>
    </div>
    <div class="body">
      <div>
        <slot></slot>
      </div>
    </div>
    <div class="btn-wrap">
      <button class="btn-close" @click="readItem(message)">{{$t('_.close')}}</button>
      <slot name="buttons"></slot>
    </div>
<!--    <button class="noti-test" @click="notiTest">Noti TEST</button>-->
  </div>
</template>

<script>
import SvgArena from '@shared/graphics/svg-arena.vue';
import Specific from '@shared/types/Specific';
import SvgLvup from '@shared/graphics/svg-lvup.vue';
import SvgTeam from '@shared/graphics/svg-team.vue';
import SvgShop from '@shared/graphics/svg-shop.vue';
import SvgTrash from '@shared/graphics/svg-trash.vue';
import SvgWcg from '@shared/graphics/svg-wcg.vue';
import NotificationItemPop from '@shared/components/notification/NotificationItemPop';
import { state } from '@shared/utils/storeUtils';

export default {
  name: 'NotificationItem',
  lexicon: 'shared.notification',
  components: { SvgTrash },
  props: {
    message: Specific,
  },
  computed: {
    unReadCount: state('notification', 'unReadCount'),
    icon() {
      switch (this.message?.type) {
        case 'TEAM':
          return SvgTeam;
        case 'TOURNAMENT':
        case 'MATCH':
          return SvgArena;
        case 'CASH':
        case 'MISSION':
          return SvgShop;
        case 'GF_POINT':
        case 'LAND_TICKET':
          return SvgWcg;
        default:
          return SvgLvup;
      }
    },
    notificationId() {
      return this.message?.userNotificationId;
    },
    date() {
      const notifyDate = this.message.updatedAt || this.message.createdAt || Date.now();
      return this.$fromNow(notifyDate);
    },
  },
  methods: {
    deleteItem() {
      this.$emit('delete-item', this.notificationId);
    },
    close() {
      this.$emit('close');
    },
    async readItem({ notificationId, userNotificationId }) {
      this.close();
      notificationId = notificationId || userNotificationId;
      if(!notificationId) return;
      await this.$services.notification.markAsRead(notificationId);
      this.$store.commit('notification/setUnreadCount', this.unReadCount - 1);
    },
    notiTest() {
      this.$modal(NotificationItemPop, { message: this.message });
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[notification-item] { .c(@c-w08); .mb(20); .p(20); .br(16); .bgc(#242a32);
  > .header { .flex; .space-between; .mb(15); .pb(17); .-b(@c-w01); .gap(12);
    .title { .flex-grow; .flex;
      .icon { .o(0.8); .wh(20); .vam; .mr(10); .flex-no-shrink;
        .stroke-target { stroke: #fff; }
        .fill-target { fill: #fff; }
      }
      .lvup-icon { .hide; .wh(50,35); }
      .wcg-icon { .hide; }
      span { .block; .break-all;}
    }
    .option { .c(@c-w05); .flex-no-shrink;
      svg { .wh(20); .o(0.5); .vam; .ml(8);
        .fill-target { fill: #fff; }
      }
      > span { .fs(12); }
    }
    span { .vam; }
  }
  > .body { .mt(18);
    p { .fs(12);
      .link { .-b(@c-w08); }
    }
    .main-msg {}
    .sub-msg { .mt(10); .o(0.8); }
    .status { .tc;
      &.expired { .mt(10); .fs(11); .o(0.8); }
    }
    .warning { .c(#e5bb30); }
    em { .c(#e5bb30); }
    .msg { .mt(8); }
    .room-box { .br(2); .bgc(#2c2c2c); .mb(16); .p(8); }
    [count-down] { .mt(20); .tc;
      &.expired { .fs(11); .o(0.8); }
    }
  }
  .btn-wrap { .mt(20); .tr;
    button { .h(40); .p(0, 20); background-image: linear-gradient(to bottom, #f7f7f9, @c-base-gray); .c(@c-b05); .fs(13); .br(20);
      &.btn-close{ .hide; }
      @{hover-press} { .c(#000); }
    }
  }
}
</style>
